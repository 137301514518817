import React, { useContext, useRef, useEffect } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { TimelineMax, Expo } from "gsap"

import {
  Box,
  ImageWrapper,
  AccentText,
  Paragraph,
  VerticalParagraph,
} from "../../helpers/styledComponents"
import theme from "../../helpers/theme"
import ScrollMagicContext from "../../helpers/scrollMagicContext"
import useMedia from "../../helpers/useMedia"
import { getAlbumSlug } from "../../helpers/utils"

const Album = props => {
  const { album, darkMode, layout, controller } = props
  const scrollMagicConsumer = useContext(ScrollMagicContext)
  const { scrollMagic } = scrollMagicConsumer
  const id = `animationTrigger${album._id}`

  let albumImage = useRef(null)
  let albumTitle = useRef(null)
  let albumMeta = useRef(null)
  const offset = useMedia(
    [
      `(min-width: ${theme.breakpoints[0]})`,
      `(min-width: ${theme.breakpoints[1]})`,
    ],
    ["-200", "20"],
    "-500"
  )

  useEffect(() => {
    const animationTimeline = new TimelineMax()
      .from(
        albumTitle.children[0],
        2,
        {
          y: 40,
          opacity: 0,
          ease: Expo.easeOut,
        },
        "start"
      )
      .from(
        albumTitle.children[1],
        2,
        {
          y: 50,
          opacity: 0,
          ease: Expo.easeOut,
        },
        "start+=0.8"
      )
      .from(
        albumImage.firstElementChild,
        2,
        { y: 20, opacity: 0, ease: Expo.easeOut },
        "1"
      )
      .from(
        albumImage.firstElementChild,
        2,
        { scale: 3, ease: Expo.easeOut },
        0.2
      )
      .from(
        albumMeta.firstElementChild,
        2,
        {
          y: 30,
          opacity: 0,
          ease: Expo.easeOut,
        },
        "start+=2"
      )

    new scrollMagic.Scene({
      triggerElement: `#${id}`,
      duration: 400,
      offset,
    })
      .setTween(animationTimeline)
      .addTo(controller)
  }, [])

  return (
    <Outer
      background={darkMode ? theme.colors.black : ""}
      textColor={darkMode ? theme.colors.white : ""}
      py={[5, 6]}
      pl={[3, 5]}
      pr={[3, layout === 1 ? 5 : 2, layout === 1 ? 6 : 2]}
    >
      <div id={id} />
      <AlbumMeta
        ref={el => {
          albumMeta = el
        }}
        order={layout === 1 ? 3 : undefined}
      >
        <VerticalParagraph
          css={{
            margin: 0,
          }}
          lineHeight={[1.2, 1.5]}
        >
          {`${album.Date} / ${album.photos.length} PHOTOS`}
        </VerticalParagraph>
      </AlbumMeta>
      <AlbumCoverContainer order={layout === 1 ? 2 : undefined}>
        <Link
          ref={el => {
            albumImage = el
          }}
          to={`/${getAlbumSlug(album.name)}`}
        >
          <ImageWrapper imageUrl={album.albumCover.photo.file.asset.fluid} />
        </Link>
      </AlbumCoverContainer>
      <AlbumTitleContainer
        order={layout === 1 ? 1 : undefined}
        flex={layout === 1 ? 1 : undefined}
      >
        <AlbumTitleInner alignItems={layout === 1 ? "flex-end" : undefined}>
          <Link
            ref={el => {
              albumTitle = el
            }}
            to={`/${getAlbumSlug(album.name)}`}
          >
            <Paragraph
              css={{
                margin: 0,
                textAlign: layout === 1 ? "right" : "left",
              }}
              lineHeight={[1.2, 1.5]}
            >
              {"Album".toUpperCase()}
            </Paragraph>
            <AccentText
              as="p"
              mt={2}
              css={{
                textAlign: layout === 1 ? "right" : "left",
              }}
            >
              {album.name.toUpperCase()}
            </AccentText>
          </Link>
        </AlbumTitleInner>
      </AlbumTitleContainer>
    </Outer>
  )
}

const Outer = styled(Box)(props => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  background: props.background,
  p: {
    color: props.textColor,
  },
}))

const AlbumMeta = styled(Box)(props => ({
  margin: "0px 20px",
  order: props.order,
}))

const AlbumCoverContainer = styled(Box)(props => ({
  flex: 4,
  order: props.order,
  overflow: "hidden",
  cursor: "pointer",
}))

const AlbumTitleContainer = styled(Box)(props => ({
  flex: props.flex || 2,
  padding: `0 ${theme.spaces[3]}px`,
  minWidth: "100px",
  order: props.order,
  cursor: "pointer",
}))

const AlbumTitleInner = styled(Box)(props => ({
  display: "flex",
  flexDirection: "column",
  alignItems: props.alignItems || "flex-start",
}))

export default Album
