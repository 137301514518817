import React from "react"

import AlbumCovers from "../components/workPage/albumCovers"
import BaseLayout from "../components/layout/base"
import { ScrollMagicProvider } from "../helpers/scrollMagicContext"
import SEO from "../components/utils/seo"

const WorkPage = props => {
  return (
    <ScrollMagicProvider>
      <BaseLayout>
        <SEO title="Work" />
        <AlbumCovers />
      </BaseLayout>
    </ScrollMagicProvider>
  )
}

export default WorkPage
