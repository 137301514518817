import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

import { Box, FullScreenContainer } from "../../helpers/styledComponents"
import theme from "../../helpers/theme"
import Album from "./album"
import ScrollMagicContext from "../../helpers/scrollMagicContext"

const AlbumCovers = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityAlbum(sort: { fields: Date, order: DESC }) {
        nodes {
          albumCover {
            photo {
              file {
                asset {
                  fluid(maxWidth: 1000) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
          Date(formatString: "YYYY")
          name
          photos {
            photo {
              id
            }
          }
          _id
        }
      }
    }
  `)
  const albums = data.allSanityAlbum.nodes

  const scrollMagicConsumer = useContext(ScrollMagicContext)
  const { scrollMagic } = scrollMagicConsumer
  let controller = new scrollMagic.Controller()

  return (
    <Outer>
      <FullScreenContainer customPadding={["0", "0", "0"]}>
        <Row>
          {albums.map((album, index) => {
            return (
              <Album
                album={album}
                key={`album${album.name.split(" ").join("")}`}
                darkMode={index % 2 === 0 ? false : true}
                layout={index % 3}
                controller={controller}
              />
            )
          })}
        </Row>
      </FullScreenContainer>
    </Outer>
  )
}

const Outer = styled(Box)({
  display: "flex",
  alignItems: "center",
  background: theme.colors.white,
  position: "relative",
})

const Row = styled(Box)({
  position: "relative",
  overflow: "hidden",
})

export default AlbumCovers
